<!--
 * @Description: 多图上传
-->
<template>
  <div class="clearfix">
    <a-upload
      name="file"
      action="/foundation/api/file"
      withCredentials
      list-type="picture-card"
      :file-list="fileList"
      accept=".jpg, .jpeg, .png"
      @preview="handlePreview"
      @change="handleChange"
    >
      <div v-if="fileList.length < maxLength">
        <a-icon type="plus" />
        <div class="ant-upload-text"></div>
      </div>
    </a-upload>
    <a-modal :visible="previewVisible" :footer="null" @cancel="handleCancel">
      <img alt="example" style="width: 100%" :src="previewImage" />
    </a-modal>
  </div>
</template>
<script>
function getBase64(file) {
  return new Promise((resolve, reject) => {
    const reader = new FileReader()
    reader.readAsDataURL(file)
    reader.onload = () => resolve(reader.result)
    reader.onerror = (error) => reject(error)
  })
}
export default {
  props: {
    // 注意如果初始化的话，
    // file 要有 uid, name, status,url 四个属性
    fileList: {
      type: Array,
      default: () => []
    },
    maxLength: {
      type: Number,
      default: 5
    }
  },
  data() {
    return {
      previewVisible: false,
      previewImage: ''
    }
  },
  methods: {
    handleCancel() {
      this.previewVisible = false
    },
    async handlePreview(file) {
      if (!file.url && !file.preview) {
        file.preview = await getBase64(file.originFileObj)
      }
      this.previewImage = file.url || file.preview
      this.previewVisible = true
    },
    handleChange({ fileList }) {
      const newFileList = fileList.map((item) => ({
        ...item,
        url: item.url || item.response?.url
      }))
      this.$emit('update:fileList', newFileList)
      this.$emit('change', newFileList)
    }
  }
}
</script>
<style>
/* you can make up upload button and sample style by using stylesheets */
.ant-upload-select-picture-card i {
  font-size: 32px;
  color: #999;
}

.ant-upload-select-picture-card .ant-upload-text {
  margin-top: 8px;
  color: #666;
}
</style>
