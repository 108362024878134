<template>
  <page-header-wrapper>
    <a-card class="card_wrapper">
      <a-form-model class="form_content" :model="form" ref="form" :rules="rules">
        <a-form-model-item prop="money" label="充值金额">
          <PriceInput class="price_input" v-model="form.money"></PriceInput>
        </a-form-model-item>

        <a-form-model-item prop="imgUrl" label="上传支付回执">
          <!-- <a-input></a-input> -->
          <MultiImageUploader :fileList.sync="form.imgUrl"></MultiImageUploader>
        </a-form-model-item>
        <a-form-model-item>
          <div class="btn_group">
            <a-button @click="handleCancel">取消</a-button>
            <span class="ml-10"></span>
            <a-button type="primary" :loading="confirmLoading" @click="handleSubmit">确认</a-button>
          </div>
        </a-form-model-item>
      </a-form-model>
    </a-card>
  </page-header-wrapper>
</template>

<script>
import MultiImageUploader from '@/components/MultiImageUploader'
import ApiController from '../api/index'
import { priceValRule } from '@/utils/rules'

const FORM = () => ({
  money: undefined,
  imgUrl: []
})

export default {
  name: 'DepositMoney',
  components: {
    MultiImageUploader
  },
  data() {
    return {
      show: false,
      confirmLoading: false,
      form: FORM(),
      rules: {
        money: priceValRule('充值金额'),
        imgUrl: [{ required: true, type: 'array', min: 0, message: '请上传回执!', trigger: 'change' }]
      }
    }
  },
  mounted() {
    if (this.$route.params.id) {
      this.refillForm()
    }
  },
  methods: {
    // 重新提交回填表单
    async refillForm() {
      const { id } = this.$route.params
      const res = (await ApiController.getItem({ id })) || {}
      this.form.money = res.balanceChange
      this.form.imgUrl = res.receipts?.map((url, index) => ({
        uid: Math.random(),
        name: `receip-${index}.jpg`,
        status: 'done',
        url
      }))
    },
    handleCancel() {
      this.$confirm({
        content: '确定要退出么？已填表单无法保存！',
        onOk: () => {
          this.$router.go(-1)
        }
      })
    },
    async handleSubmit() {
      const _action = async () => {
        const params = {
          id: this.$route.params.id,
          ...this.form,
          imgUrl: this.form.imgUrl.map((item) => item.url)
        }

        try {
          this.confirmLoading = true
          await this.$refs.form.validate()
          await ApiController.recharge(params)
          this.$message.success('充值成功!')
          this.$router.go(-1)
        } catch (error) {
          console.log(error)
        } finally {
          this.confirmLoading = false
        }
      }

      this.$confirm({
        type: 'warning',
        title: '确认充值？',
        onOk: _action
      })
    }
  }
}
</script>

<style lang="scss" scoped>
.card_wrapper {
  height: calc(100vh - 190px);
}

.form_content {
  max-width: 900px;
  .price_input {
    max-width: 400px;
  }
}

.btn_group {
  margin-top: 40px;
  display: flex;
  justify-content: flex-end;
}
</style>
