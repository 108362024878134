var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "clearfix" },
    [
      _c(
        "a-upload",
        {
          attrs: {
            name: "file",
            action: "/foundation/api/file",
            withCredentials: "",
            "list-type": "picture-card",
            "file-list": _vm.fileList,
            accept: ".jpg, .jpeg, .png",
          },
          on: { preview: _vm.handlePreview, change: _vm.handleChange },
        },
        [
          _vm.fileList.length < _vm.maxLength
            ? _c(
                "div",
                [
                  _c("a-icon", { attrs: { type: "plus" } }),
                  _c("div", { staticClass: "ant-upload-text" }),
                ],
                1
              )
            : _vm._e(),
        ]
      ),
      _c(
        "a-modal",
        {
          attrs: { visible: _vm.previewVisible, footer: null },
          on: { cancel: _vm.handleCancel },
        },
        [
          _c("img", {
            staticStyle: { width: "100%" },
            attrs: { alt: "example", src: _vm.previewImage },
          }),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }