/*
 * @Description: 财务管理相关接口
 */
import { requestCustom as request } from '@/utils/request'

const BASE_URL = `/api/organization/financeManagements`

// 查看余额
function getBalance(params) {
  return request({
    url: `${BASE_URL}/balances`,
    method: 'get',
    params
  })
}

// 查看财务管理列表
function getList(params) {
  return request({
    url: `${BASE_URL}`,
    method: 'get',
    params
  })
}

// 财务管理-查询单个充值记录信息
function getItem(params) {
  return request({
    url: `${BASE_URL}/${params.id}`,
    method: 'get',
    params
  })
}

// 查看回执
function viewReceipt(params) {
  return request({
    url: `${BASE_URL}/financeReceipt/${params.id}`,
    method: 'get',
    params
  })
}

// 查看驳回原因
function rejectReason(params) {
  return request({
    url: `${BASE_URL}/rejected/${params.id}`,
    method: 'get',
    params
  })
}

// 充值
function recharge(params) {
  return request({
    url: `${BASE_URL}/recharge`,
    method: 'put',
    data: params
  })
}

// 财务管理-充值订单管理-查看回执
function viewReceiptList(params) {
  return request({
    url: `${BASE_URL}/financeReceipt/${params.id}`,
    method: 'get',
    params
  })
}

export {
  viewReceiptList
}

export default {
  getList,
  getItem,
  getBalance,
  viewReceipt,
  rejectReason,
  recharge,
  viewReceiptList,
}
